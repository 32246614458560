import React from "react";
import * as Typ from "../typography/typography";
import "./contentWarning.scss";
import { ReactComponent as WarningIcon } from "../../icons/warning_stroke2_corner0_rounded.svg";

export type ContentWarningProps = Omit<React.HTMLProps<HTMLDetailsElement>,"label"> & {
  icon?: React.ReactNode,
  label?: React.ReactNode,
  children?: React.ReactNode,
}
export function ContentWarning({ icon, label, children, className, ...props }: ContentWarningProps) {
  return <details className={["sfu-content-warning", className].join(" ")}>
    <summary>{icon || <WarningIcon className="icon" />}{typeof label == "string" ? <Typ.Hint as="p">{label}</Typ.Hint> : label}
      <Typ.Caption addClass="show">Show</Typ.Caption>
      <Typ.Caption addClass="hide">Hide</Typ.Caption>
    </summary>
    {children}
  </details>
}
