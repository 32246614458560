import * as ATP from "@atproto/api";
import { AppBskyGraphList } from "@atproto/api";
import { DidResolver } from "@atproto/identity/dist/did";
import { WrappedRecordWithAuthorInfo } from "../types/Record";
import SkyfeedRecordValue from "../types/SkyfeedBuilder";

interface GetFeedRecordOpts {
  setError: (value: string|null) => any;
  setLoading: (value: boolean) => any;
  isDiscarded: () => boolean;
  idResolver?: DidResolver
}
export async function getListRecord(uri: string, { setError, setLoading, isDiscarded, idResolver }: GetFeedRecordOpts): Promise<WrappedRecordWithAuthorInfo<AppBskyGraphList.Record>|undefined> {
  idResolver ??= new DidResolver({});
  const agent = new ATP.Agent("https://public.api.bsky.app");
  if (uri.startsWith("https://bsky.app/profile/") && uri.includes("/lists/")) {
    // it's probably a feed URL, convert it
    // this is a super rudimentary conversion, but it works
    uri = uri.replace("https://bsky.app/profile/", "at://").replace("/lists/", "/app.bsky.graph.list/");
    if (uri.endsWith("/")) uri = uri.substring(0, uri.length-2);
  } else if (uri.startsWith("at://") && uri.includes("/app.bsky.graph.list/")) {
    // It's probably a feed generator AT URI. Let it pass.
  } else {
    setError("The URI has to be either an at:// URI or a Bluesky link to the feed.")
    return;
  }
  const aturi = new ATP.AtUri(uri);
  const response = await agent.com.atproto.repo.getRecord({
    repo: aturi.host,
    collection: aturi.collection,
    rkey: aturi.rkey,
  }).catch((e) => {
    setError(e.toString());
    setLoading(false);
    return {
      data: {uri: aturi.toString()},
      success: false,
      headers: {}
    } as ATP.ComAtprotoRepoGetRecord.Response;
  });
  if (!response.success) return;
  const value = response.data.value;
  if (ATP.AppBskyGraphList.isRecord(value)) {
    if (isDiscarded()) return;
    // Next: do an identity lookup!!!
    // This is needed to get the PDS host
    // and the handle.
    let did = aturi.host;
    if (!aturi.host.startsWith("did:")) {
      const handleResponse = await agent.resolveHandle({handle: aturi.host})
      if (!handleResponse.success) return;
      did = handleResponse.data.did;
      if (aturi.host == did) {
        setError("The handle in the URI could not be resolved.")
        setLoading(false);
        return; // we did something wrong
      }
    }
    const didResult = await idResolver.resolve(did);
    if (isDiscarded()) return;
    if (didResult == null) {
      setError("Could not resolve DID document.")
      setLoading(false);
      return;
    }
    setError(null);
    setLoading(false);
    return {
      value,
      uri: response.data.uri,
      cid: response.data.cid,
      authorDid: didResult,
    };
  }
}
