import React from "react";
import "./typography.scss";

// NOTE: Import this file as "Typ", i.e. import * as Typ from "./components/typography/typography";

export type TypographyProps = React.HTMLProps<HTMLElement> & {
  addClass?: string,
}

const TYPOGRAPHY_CLASS_PREFIX = "sfu-typography--";
function Typography(typographyClass: string, {children, as = "span", addClass, ...props}: TypographyProps) {
  const As = as as React.ElementType;
  return <As className={[typographyClass, ...(addClass?.split(" ") || [])].join(" ")} {...props}>{children}</As>
}

export function Body(props: TypographyProps) {
  return Typography(TYPOGRAPHY_CLASS_PREFIX+"body", props);
}
export function SiteTitle(props: TypographyProps) {
  return Typography(TYPOGRAPHY_CLASS_PREFIX+"site-title", props);
}
export function Display(props: TypographyProps) {
  return Typography(TYPOGRAPHY_CLASS_PREFIX+"display", props);
}
export function ObjectTitle(props: TypographyProps) {
  return Typography(TYPOGRAPHY_CLASS_PREFIX+"object-title", props);
}
export function Hint(props: TypographyProps) {
  return Typography(TYPOGRAPHY_CLASS_PREFIX+"hint", props);
}
export function Caption(props: TypographyProps) {
  return Typography(TYPOGRAPHY_CLASS_PREFIX+"caption", props);
}
