import React, { FormEvent, useEffect, useRef, useState } from 'react';
import * as ATP from "@atproto/api";
import * as Typ from "./components/typography/typography";
import './App.scss';
import { Button } from './components/button/button';
import SkyfeedRecordValue from './types/SkyfeedBuilder';
import {  WrappedRecordWithAuthorInfo } from './types/Record';
import { DidResolver } from "@atproto/identity/dist/did";
import { ReactComponent as InfoIcon } from "./icons/circleInfo_stroke2_corner0_rounded.svg";
import { ReactComponent as AtUriIcon } from "./icons/MdiAtUri.svg";
import { getFeedRecord } from './network/getFeed';
import { BlueskyFeed } from './components/bluesky/feed';
import { SkyfeedBlock, StartTriangle } from './components/skyfeedBlock/skyfeedBlock';
import { TextField } from './components/textField/textField';

//const FeedUri = window.location.search "at://did:plc:5bzztbxvhii56ryi3az7lvzh/app.bsky.feed.generator/aaapnsxmqb3am";
//const FeedUri = "at://bsky.app/app.bsky.feed.generator/whats-hot";
//const FeedUri = "https://bsky.app/profile/halsco.de/feed/aaapnsxmqb3am";
function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const feedUri = queryParams.get("uri")
  const idResolverRef = useRef<DidResolver|undefined>(undefined);
  if (idResolverRef.current === undefined) {
    idResolverRef.current = new DidResolver({});
  }
  const [isLoading, setLoading] = useState(false);
  const [recordResponse, setRecordResponse] = useState<WrappedRecordWithAuthorInfo<SkyfeedRecordValue> | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (!feedUri) return;
    let discard = false;
    setLoading(true);
    getFeedRecord(feedUri, {
      setLoading,
      setError,
      idResolver: idResolverRef.current,
      isDiscarded: () => discard
    }).then((value) => {
      document.title = `${value?.value.displayName} — HAARP`;
      return setRecordResponse(value);
    });
    return () => {
      discard = true;
    };
  }, [feedUri]);

  function submitUriForm(event: FormEvent<HTMLFormElement>) {
    // TODO: Validate the URI. It must be a Bluesky URL or an AT URI,
    // which then also has to point to the feed.
    // Use event.preventDefault() and setError() to flag invalid input.
    const formData = new FormData(event.currentTarget);
    const uriString = formData.get("uri")?.toString();
    if (!uriString) {
      setError("Cannot look up feeds from an empty URI.");
      event.preventDefault();
      return;
    } else if (uriString.startsWith("at://")) {
      try {
        new ATP.AtUri(uriString || "");
      } catch (e) {
        setError((e as any).toString());
        event.preventDefault();
        return;
      }
    } else if (uriString.startsWith("https://bsky.app/profile/") && uriString.includes("/feed/")) {
      // eh, just let it through for now I guess
    } else {
      setError("We need a Bluesky feed link or an AT URI to a Bluesky feed to look up that feed. Please supply the correct type of URI.");
      event.preventDefault();
      return;
    }
    // TODO: transition within the SPA???
  }

  return (
    <div className="sfu-root">
      <div className="sfu-layout--flex sfu-layout--only-large"></div>
      <div className="sfu-layout--fixed-width">
        <header className="sfu-site-header" onClick={() => window.location.assign("/")}>
          <Typ.SiteTitle as="h1"><a href="/">HAARP</a></Typ.SiteTitle>
          <Typ.Caption as="div">Detect the makeup of feeds in the Atmosphere</Typ.Caption>
        </header>
        {recordResponse && <BlueskyFeed feed={recordResponse} primary />}
        {!recordResponse && <div className='sfu-empty-state--wrapper pad-bottom-a-lot pad-top-a-lot'>
            <InfoIcon width={32} height={32} />
            <Typ.Display as='h2'>Choose a feed</Typ.Display>
            {/* TODO: enable feed search? & login to see your saved feeds */}
            <Typ.Body as='p'>Put in the feed's Bluesky URL or AT URI, and press Enter (or Submit/Send).</Typ.Body>
            {/* <Button primary>Choose</Button> */}
            <form style={{display: "contents"}} onSubmit={submitUriForm} >
              <TextField 
                className='pad-top-a-little'
                name="uri" 
                placeholder='at://.../app.bsky.feed.generator/...'
                containerProps={{style: {width: "100%", maxWidth: "400px"}}}
                prefix={<AtUriIcon className="light-up icon" aria-label="AT URI icon: an At symbol with colon-slash-slash superimposed onto the bottom right corner."/>}
              />
              <button type="submit" style={{display: "none"}} />
            </form>
          </div>}
        {error && <div className='sfu-error'>{error}</div>}
        {recordResponse && <div className='sfu-feed-button-row'><Button className='sfu-feed-button' 
          href={`https://bsky.app/profile/${new ATP.AtUri(recordResponse.uri).host}/feed/${new ATP.AtUri(recordResponse.uri).rkey}`}
          target="_blank"
        >View on Bluesky</Button></div>}
        {recordResponse && (recordResponse?.value.skyfeedBuilder
          ? <div id="skyfeed-blocks-container pad-bottom-a-lot">
            {recordResponse.value.skyfeedBuilder.blocks.map((v,_,__) => <SkyfeedBlock block={v} />)}
            <div className="sfu-skyfeed-block--end"><StartTriangle /></div>
          </div>
          : <div className='sfu-empty-state--wrapper'>
            <InfoIcon width={32} height={32} />
            <Typ.Display as='h2'>Not a Skyfeed</Typ.Display>
            <Typ.Body as='p'>This feed wasn't made with Skyfeed, so its details can't be shown here.</Typ.Body>
            <Button primary>Retry</Button>
          </div>
        )}
      </div>
      <div className="sfu-layout--flex sfu-layout--only-large"></div>
    </div>
  );
}

export default App;
