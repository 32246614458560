import React from 'react';
import './button.scss';

type ButtonProps = {
  primary?: boolean;
  href?: string;
  className?: string;
  [key: string]: any;
};
export function Button({ primary = false, children, className, ...props }: ButtonProps) {
  const Element = props.href ? "a" : "button";
  return <Element className={['sfu-button', 'sfu-typography--body', (primary && 'sfu-button--primary'), ...(className?.split(" ") || [])].join(" ")} {...props}>
    {children}
  </Element>;
}
