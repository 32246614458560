import React, { useState } from "react";
import * as Typ from "../typography/typography";
import * as model from "../../types/SkyfeedBuilder";
import { ReactComponent as RegexIcon } from "../../icons/MdiRegex.svg";
import { TextField } from "../textField/textField";

type RegexMatchTesterProps = {
  block: model.SkyfeedRegexBlock,
}
export function RegexMatchTester({ block, ...props }: RegexMatchTesterProps) {
  const [valid, setValid] = useState(true);
  const [empty, setEmpty] = useState(true);
  try {
    const expression = RegExp(block.value, ["v", block.caseSensitive ? "" : "i"].join(""));
    const expressionMatchesEmpty = expression.test("");
    return <>
      <TextField 
        type="text"
        placeholder="Test some text against the pattern"
        pattern={".*"+(block as model.SkyfeedRegexBlock).value+".*"}
        className={`sfu-skyfeed-regex--match-tester ${empty ? "" : valid ? "valid" : "invalid"}`}
        prefix={<RegexIcon className="icon smaller light-up" />}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setEmpty(value == "");
          setValid(!!value.match(expression));
        }}
      />
      {empty && <Typ.Caption as="p" addClass="pad-top-a-little">Empty posts are {expressionMatchesEmpty || block.invert ? "kept" : "removed"}.</Typ.Caption>}
      {valid && !empty && <Typ.Caption as="p" addClass="pad-top-a-little">This post matches the pattern, so it would be {block.invert ? "removed" : "kept"}.</Typ.Caption>}
      {!valid && !empty && <Typ.Caption as="p" addClass="pad-top-a-little">This post does not match the pattern, so it would be {block.invert ? "kept" : "removed"}.</Typ.Caption>}
    </>
  } catch(e) {
    console.error(e);
    return <Typ.Body as="div" addClass="sfu-error">The expression is invalid, so a tester could not be provided.</Typ.Body>;
  }
}
