import { WrappedRecordWithAuthorInfo } from "../../types/Record"
import { BlueskyObject } from "./object"
import * as ATProto from "@atproto/api";
import { DidResolver, getHandle } from "@atproto/identity/dist/did";
import { useEffect, useRef, useState } from "react";
import { getFeedRecord } from "../../network/getFeed";
import { AppBskyGraphList } from "@atproto/api";
import { getListRecord } from "../../network/getList";

type BlueskyListProps = React.HTMLProps<HTMLDivElement> & {
  primary?: boolean,
  last?: string | WrappedRecordWithAuthorInfo<AppBskyGraphList.Record>,
  shouldLink?: boolean,
  loading?: boolean,
}
export function BlueskyList({ list, loading, ...props }: BlueskyListProps) {
  if (loading || !list) return <BlueskyObject
    loading={true}
    title=""
    typeName="list"
    authorDid=""
    {...props}
  />
  else if (typeof list == "object") return <KnownBlueskyList list={list} {...props} />
  else if (typeof list == "string") return <UnknownBlueskyList list={list} {...props} />
  else return <div className='sfu-error'>Unknown feed</div>
}

type KnownBlueskyListProps = Omit<React.HTMLProps<HTMLDivElement>,"list"> & {
  primary?: boolean,
  list: WrappedRecordWithAuthorInfo<AppBskyGraphList.Record>,
  shouldLink?: boolean,
  loading?: boolean,
}
function KnownBlueskyList({ list, shouldLink, loading, ...props }: KnownBlueskyListProps) {
  const uri = new ATProto.AtUri(list.uri);
  const object = <BlueskyObject
    title={list.value.name}
    typeName={list.value.purpose == "app.bsky.graph.defs#modlist" ? "Moderation list" : "User list"}
    authorDid={uri.host}
    authorHandle={getHandle(list.authorDid)}
    imageUrl={list.value.avatar && `${list.authorDid.service?.find((v,_,__) => v.type == "AtprotoPersonalDataServer")?.serviceEndpoint}/xrpc/com.atproto.sync.getBlob?did=${encodeURIComponent(uri.host)}&cid=${encodeURIComponent(list.value.avatar.ref.toString())}`}
    description={list.value.description}
    // TODO: description facets!
    {...props}
  />;
  if (shouldLink) {
    return <a className="sfu-link-tile" target="_blank" href={`https://bsky.app/profile/${uri.host}/lists/${uri.rkey}`}>{object}</a>;
  } else return object;
}

type UnknownBlueskyListProps = React.HTMLProps<HTMLDivElement> & {
  primary?: boolean,
  shouldLink?: boolean,
  list: string,
}
function UnknownBlueskyList({ list, primary, shouldLink, ...props }: UnknownBlueskyListProps) {
  const idResolverRef = useRef<DidResolver|undefined>(undefined);
  if (idResolverRef.current === undefined) {
    idResolverRef.current = new DidResolver({});
  }
  const [isLoading, setLoading] = useState(false);
  const [recordResponse, setRecordResponse] = useState<WrappedRecordWithAuthorInfo<AppBskyGraphList.Record> | undefined>(typeof list == "string" ? undefined : list);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (recordResponse) return;
    let discard = false;
    setLoading(true);
    getListRecord(list, {
      setLoading,
      setError,
      idResolver: idResolverRef.current,
      isDiscarded: () => discard
    }).then((value) => setRecordResponse(value));
    return () => {
      discard = true;
    };
  }, [list]);

  if (error) return <div className='sfu-error'>{error}</div>;

  if (!recordResponse) return <BlueskyObject
    loading={true}
    title=""
    typeName="List"
    authorDid=""
    {...props}
  />;

  return <KnownBlueskyList shouldLink={shouldLink} list={recordResponse} {...props} />
}
