import { WrappedRecordWithAuthorInfo } from "../../types/Record"
import SkyfeedRecordValue from "../../types/SkyfeedBuilder"
import { BlueskyObject } from "./object"
import * as ATProto from "@atproto/api";
import { DidResolver, getHandle } from "@atproto/identity/dist/did";
import { useEffect, useRef, useState } from "react";
import { getFeedRecord } from "../../network/getFeed";

type BlueskyFeedProps = React.HTMLProps<HTMLDivElement> & {
  primary?: boolean,
  feed?: string | WrappedRecordWithAuthorInfo<SkyfeedRecordValue>,
  shouldLink?: boolean,
  loading?: boolean,
}
export function BlueskyFeed({ feed, loading, ...props }: BlueskyFeedProps) {
  if (loading || !feed) return <BlueskyObject
    loading={true}
    title=""
    typeName=""
    authorDid=""
    {...props}
  />
  else if (typeof feed == "object") return <KnownBlueskyFeed feed={feed} {...props} />
  else if (typeof feed == "string") return <UnknownBlueskyFeed feed={feed} {...props} />
  else return <div className='sfu-error'>Unknown feed</div>
}

type KnownBlueskyFeedProps = React.HTMLProps<HTMLDivElement> & {
  primary?: boolean,
  feed: WrappedRecordWithAuthorInfo<SkyfeedRecordValue>,
  shouldLink?: boolean,
  loading?: boolean,
}
function KnownBlueskyFeed({ feed, shouldLink, loading, ...props }: KnownBlueskyFeedProps) {
  const uri = new ATProto.AtUri(feed.uri);
  const object = <BlueskyObject
    title={feed.value.displayName}
    typeName="Feed"
    authorDid={uri.host}
    authorHandle={getHandle(feed.authorDid)}
    imageUrl={feed.value.avatar && `${feed.authorDid.service?.find((v,_,__) => v.type == "AtprotoPersonalDataServer")?.serviceEndpoint}/xrpc/com.atproto.sync.getBlob?did=${encodeURIComponent(uri.host)}&cid=${encodeURIComponent(feed.value.avatar.ref.toString())}`}
    description={feed.value.description}
    // TODO: description facets!
    {...props}
    //authorHandle=
  />;
  if (shouldLink) {
    return <a className="sfu-link-tile" target={feed.value.skyfeedBuilder ? undefined : "_blank"} href={feed.value.skyfeedBuilder ? `/?uri=${uri}` : `https://bsky.app/profile/${uri.host}/feed/${uri.rkey}`}>{object}</a>;
  } else return object;
}

type UnknownBlueskyFeedProps = React.HTMLProps<HTMLDivElement> & {
  primary?: boolean,
  feed: string,
}
function UnknownBlueskyFeed({ feed, primary, ...props }: UnknownBlueskyFeedProps) {
  const idResolverRef = useRef<DidResolver|undefined>(undefined);
  if (idResolverRef.current === undefined) {
    idResolverRef.current = new DidResolver({});
  }
  const [isLoading, setLoading] = useState(false);
  const [recordResponse, setRecordResponse] = useState<WrappedRecordWithAuthorInfo<SkyfeedRecordValue> | undefined>(typeof feed == "string" ? undefined : feed);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (recordResponse) return;
    let discard = false;
    setLoading(true);
    getFeedRecord(feed, {
      setLoading,
      setError,
      idResolver: idResolverRef.current,
      isDiscarded: () => discard
    }).then((value) => setRecordResponse(value));
    return () => {
      discard = true;
    };
  }, [feed]);

  if (error) return <div className='sfu-error'>{error}</div>;

  if (!recordResponse) return <BlueskyObject
    loading={true}
    title=""
    typeName="feed"
    authorDid=""
    primary={primary}
    {...props}
  />;

  return <KnownBlueskyFeed primary={primary} feed={recordResponse} loading={isLoading} {...props} />
}
