import React from "react";
import * as model from "../../types/SkyfeedBuilder";
import * as Typ from "../typography/typography";
import { BlueskyFeed } from "../bluesky/feed";
import "./skyfeedBlock.scss";
import { ReactComponent as AddIcon } from "../../icons/MaterialSymbolsAddRounded.svg";
import { ReactComponent as TrashCanIcon } from "../../icons/MdiTrashCanOutline.svg";
import { ReactComponent as AlignBottomIcon } from "../../icons/MdiFormatAlignBottom.svg";
import { ReactComponent as SortIcon } from "../../icons/MaterialSymbolsSortRounded.svg";
import { ReactComponent as ErrorIcon } from "../../icons/warning_stroke2_corner0_rounded.svg";
import { ReactComponent as FireHydrantIcon } from "../../icons/TablerFireHydrant.svg";
import { ReactComponent as FilterIcon } from "../../icons/MdiFilter.svg";
import { ReactComponent as ArchiveIcon } from "../../icons/MdiArchive.svg";
import { ReactComponent as LoadDiscIcon } from "../../icons/LucideDisc3.svg";
import { ReactComponent as SaveIcon } from "../../icons/MaterialSymbolsSaveRounded.svg";
import { ReactComponent as SwapIcon } from "../../icons/RiSwap2Line.svg";
import { ReactComponent as _StartTriangle } from "./start.svg";
import { BlueskyList } from "../bluesky/list";
import prettyMilliseconds from "pretty-ms";
import { RegexMatchTester } from "./regex";
import { ContentWarning } from "../contentWarning/contentWarning";
import { BlueskyLabelTile } from "../bluesky/label";
import { AtUri } from "@atproto/api";
import { TextDecoderStream } from "stream/web";
import { Button } from "../button/button";

export const StartTriangle = _StartTriangle;

type BlockInternalOpts = {
  id?: string,
  addClasses?: string,
  child: React.JSX.Element,
  icon: React.JSX.Element,
  isError?: boolean,
  type?: "input" | "remove" | "limit" | "regex" | "stash" | "pop" | "replace" | "sort" | "remember_posts" | "firehose",
  typeTooltip?: string,
}
type SkyfeedBlockProps = React.HTMLProps<HTMLElement> & {
  block: model.SkyfeedBlock
}
export function SkyfeedBlock({ block }: SkyfeedBlockProps) {
  function _block(opts: BlockInternalOpts) {
    return <div 
      key={block.id}
      id={opts.id || block.id}
      className={["sfu-skyfeed-block", ...(opts.addClasses ? [opts.addClasses] : []), ...(opts.type ? [`sfu-skyfeed-block_${opts.type}`] : [])].join(" ")} 
    >
      <div className="thread"><div className={`bauble${opts.isError ? " error" : ""}`} data-tooltip={opts.typeTooltip || undefined}>{opts.icon}</div></div>
      <div className="thread-spacer" />
      {opts.child}
    </div>;
  }
  function _regexBlockTargets(block: model.SkyfeedRegexBlock) : [string, number] {
    const targetFlags = block.target?.split("|") ?? ["text"];
    const targets = targetFlags.map((v, _, __) => {
      if (v == "text") return "the post text";
      if (v == "alt_text") return "any alt text";
      if (v == "link") return "any link embed";
      return v;
    });
    if (targets.length > 2) {
      const og = targets.pop();
      targets.push((block.invert ? "and " : "or ")+og);
      return [targets.join(", "), targets.length];
    } else if (targets.length == 2) return [targets.join(block.invert ? " and " : " or "), targets.length]
      else return [targets.join(", "), targets.length];
  }
  if (block.type == "input" && (block as model.SkyfeedInputBlock).inputType == "post") {
    return _block({
      child: <>
        <blockquote onMouseEnter={() => (window as any).bluesky?.scan()} className="bluesky-embed" data-bluesky-uri={(block as model.SkyfeedSinglePostInputBlock).postUri}>
          <a className="fallback" target="_blank" href={convertAtUriToPostUri((block as model.SkyfeedSinglePostInputBlock).postUri)}>View this post on Bluesky</a>
        </blockquote>
      </>,
      icon: <AddIcon />,
      type: "input",
      typeTooltip: "Add single post",
    });
  } else if (block.type == "input" && (block as model.SkyfeedInputBlock).inputType == "feed") {
    return _block({
      child: <BlueskyFeed shouldLink feed={(block as model.SkyfeedFeedInputBlock).feedUri} />,
      icon: <AddIcon />,
      type: "input",
      typeTooltip: "Add feed",
    });
  } else if (block.type == "input" && (block as model.SkyfeedInputBlock).inputType == "list") {
    return _block({
      child: <BlueskyList shouldLink list={(block as model.SkyfeedListInputBlock).listUri} />,
      icon: <AddIcon />,
      type: "input",
      typeTooltip: "Add list",
    });
  } else if (block.type == "input" && (block as model.SkyfeedInputBlock).inputType == "tags") {
    return _block({
      child: <div className="pad-tags">
        <Typ.Hint>Add all posts that have any of these hashtags</Typ.Hint>
        <div className="sfu-skyfeed-block--tags-row">
          {(block as model.SkyfeedInputTagsBlock).tags.map((v,_,__) => <a 
            className="tag"
            key={v}
            href={`https://bsky.app/hashtag/${v}`}
            target="_blank"
          >#{v}</a>)}
        </div>
      </div>,
      icon: <AddIcon />,
      type: "input",
      typeTooltip: "Add",
    });
  } else if (block.type == "input" && (block as model.SkyfeedInputBlock).inputType == "labels") {
    return _block({
      child: <div className="pad-hint">
        <Typ.Hint>Add all posts that have any of these labels</Typ.Hint>
        {(block as model.SkyfeedInputLabelsBlock).labels.map((v,_,__) => <BlueskyLabelTile 
          label={v}
          key={v}
          shouldLink
        />)}
      </div>,
      icon: <AddIcon />,
      type: "input",
      typeTooltip: "Add",
    });
  } else if (block.type == "input" && (block as model.SkyfeedInputBlock).inputType == "firehose") {
    return _block({
      child: <Typ.Body as="p">Get all posts from the past {prettyMilliseconds((block as model.SkyfeedFirehoseInputBlock).firehoseSeconds*1000, {verbose: true})}</Typ.Body>,
      icon: <FireHydrantIcon />,
      type: "firehose",
      typeTooltip: "Firehose",
    });
  } else if (block.type == "limit") {
    return _block({
      child: <Typ.Hint as="p">Limit to {(block as model.SkyfeedLimitBlock).count} posts</Typ.Hint>,
      icon: <AlignBottomIcon />,
      type: "limit",
      typeTooltip: "Limit",
    });
  } else if (block.type == "regex") {
    const [targets, count] = _regexBlockTargets(block as model.SkyfeedRegexBlock);
    const invert = (block as model.SkyfeedRegexBlock).invert;
    return _block({
      child: <div className="sfu-skyfeed-regex">
        <Typ.Hint as="p">Filter to posts where {targets} {invert && (count == 1 ? "does not" : "do not")} {!invert && count == 1 ? "matches" : "match"} a pattern</Typ.Hint>
        <RegexMatchTester block={block as model.SkyfeedRegexBlock} />
        <ContentWarning 
          className="pad-top-a-little" 
          label="The Regex pattern">
          <textarea className="style-me" readOnly>{(block as model.SkyfeedRegexBlock).value}</textarea>
        </ContentWarning>
      </div>,
      icon: <FilterIcon className="smaller" />,
      type: "regex",
      typeTooltip: "Regex",
    });
  } else if (block.type == "remember_posts") {
    return _block({
      child: <Typ.Hint as="p">Add above to feed archive; add previously archived posts</Typ.Hint>,
      icon: <ArchiveIcon className="smaller" />,
      type: "remember_posts",
      typeTooltip: "Remember Posts",
    });
  } else if (block.type == "stash" && (block as model.SkyfeedStashPopBlock).action == "stash") {
    return _block({
      child: <>
        <Typ.Hint as="p">Stash these posts as "{(block as model.SkyfeedStashPopBlock).key}" for later</Typ.Hint>
        <Button onClick={linkToPopEvent} href={`#pop-${(block as model.SkyfeedStashPopBlock).key}`} className="float-right shrink">Jump</Button>
      </>,
      icon: <SaveIcon />,
      id: "stash-"+(block as model.SkyfeedStashPopBlock).key,
      type: "stash",
      typeTooltip: "Stash",
    });
  } else if (block.type == "stash" && (block as model.SkyfeedStashPopBlock).action == "pop") {
    return _block({
      child: <>
        <Typ.Hint as="p">Bring back the posts from the stash named "{(block as model.SkyfeedStashPopBlock).key}"</Typ.Hint>
        <Button onClick={linkToStashEvent} href={`#stash-${(block as model.SkyfeedStashPopBlock).key}`} className="float-right shrink">Jump</Button>
      </>,
      icon: <LoadDiscIcon />,
      id: "pop-"+(block as model.SkyfeedStashPopBlock).key,
      type: "pop",
      typeTooltip: "Pop from stash",
    });
  } else if (block.type == "remove" && (block as model.SkyfeedRemoveBlock).subject == "duplicates") {
    return _block({
      child: <Typ.Hint as="p">Remove duplicates</Typ.Hint>,
      icon: <TrashCanIcon />,
      type: "remove",
      typeTooltip: "Remove",
    });
  } else if (block.type == "remove" && (block as model.SkyfeedRemoveBlock).subject == "item") {
    const itemValue = (block as model.SkyfeedRemoveItemBlock).value;
    let itemValueString: string;
    switch (itemValue) {
      case "post": 
        itemValueString = "posts that aren't replies";
        break;
      case "reply":
        itemValueString = "replies";
        break;
      case "repost":
        itemValueString = "boosted posts";
        break;
      case "has_labels":
        itemValueString = "posts with self-labels";
        break;
      case "has_no_labels":
        itemValueString = "posts without self-labels";
        break;
      case "hellthread":
        itemValueString = "posts in Hellthread";
        break;
      case "not_hellthread":
        itemValueString = "posts that aren't in Hellthread";
        break;
      default:
        itemValueString = itemValue;
    }
    return _block({
      child: <Typ.Hint as="p">Remove {itemValueString}</Typ.Hint>,
      icon: <TrashCanIcon />,
      type: "remove",
      typeTooltip: "Remove",
    });
  } else if (block.type == "remove" && (block as model.SkyfeedRemoveBlock).subject == "list") {
    return _block({
      child: <div className="pad-hint">
        <Typ.Hint>Remove posts from users on this list</Typ.Hint>
        <BlueskyList shouldLink list={(block as model.SkyfeedRemoveListBlock).listUri} />
      </div>,
      icon: <TrashCanIcon />,
      type: "remove",
      typeTooltip: "Remove list",
    });
  } else if (block.type == "remove" && (block as model.SkyfeedRemoveBlock).subject == "labels") {
    return _block({
      child: <div className="pad-hint">
        <Typ.Hint>Remove posts with any of these labels</Typ.Hint>
        {(block as model.SkyfeedRemoveLabelsBlock).labels.map((v,_,__) => <BlueskyLabelTile 
          label={v}
          key={v}
          shouldLink
        />)}
      </div>,
      icon: <TrashCanIcon />,
      type: "remove",
      typeTooltip: "Remove list",
    });
  } else if (block.type == "replace") {
    const itemValue = (block as model.SkyfeedReplaceBlock).with;
    let itemValueString: string;
    switch (itemValue) {
      case "root": 
        itemValueString = "the root post of their thread";
        break;
      case "parent":
        itemValueString = "the parent post"
        break;
      case "record":
        itemValueString = "the post it quotes"
        // Yes, I actually tested this to figure it out.
        break;
      default:
        itemValueString = itemValue;
    }
    return _block({
      child: <Typ.Hint as="p">Replace posts with {itemValueString}, if any</Typ.Hint>,
      icon: <SwapIcon />,
      type: "replace",
      typeTooltip: "Replace",
    });
  } else if (block.type == "sort" && (block as model.SkyfeedSortBlock).sortType == "hn") {
    return _block({
      child: <Typ.Hint as="p">Sort by Hackernews ranking algorithm (Gravity: {(block as model.SkyfeedSortHNBlock).gravity})</Typ.Hint>,
      icon: <SortIcon />,
      type: "sort",
      typeTooltip: "Sort",
    });
  } else if (block.type == "sort" && (block as model.SkyfeedSortBlock).sortType == "random") {
    return _block({
      child: <Typ.Hint as="p">Randomize order</Typ.Hint>,
      icon: <SortIcon />,
      type: "sort",
      typeTooltip: "Sort",
    });
  } else if (block.type == "sort") {
    const sortBlock = block as model.SkyfeedSortBlock;
    const sortTypes = new Map<string, string>(Object.entries({
      "reply_count": "number of replies",
      "likes": "number of likes",
      "reposts": "number of boosts",
      "created_at": "creation date",
    }));
    const sortDirs = new Map<string, string>(Object.entries({
      "asc": ", from lowest to highest",
      "desc": ", from highest to lowest",
      "dt_asc": " (oldest first)",
      "dt_desc": " (newest first)",
    }));
    return _block({
      child: <Typ.Hint as="p">Sort by {sortTypes.get(sortBlock.sortType) || sortBlock.sortType}{
        sortBlock.sortType == "created_at"
        ? sortDirs.get("dt_"+(sortBlock.sortDirection || "desc")) || " (unknown direction)"
        : sortDirs.get(sortBlock.sortDirection || "") || " (unknown direction)"
      }</Typ.Hint>,
      icon: <SortIcon />,
      type: "sort",
      typeTooltip: "Sort",
    });
  }
  // Unknown sub-types
  if (block.type == "input") {
    return _block({
      isError: true,
      icon: <AddIcon />,
      child: <p>Unknown input block type "{(block as model.SkyfeedInputBlock).inputType}"</p>,
      typeTooltip: "Error",
    });
  } else if (block.type == "remove") {
    return _block({
      isError: true,
      icon: <TrashCanIcon />,
      typeTooltip: "Error",
      child: <p>Unknown remove block type "{(block as model.SkyfeedRemoveBlock).subject}"</p>
    });
  } else {
    return _block({
      isError: true,
      icon: <ErrorIcon />,
      typeTooltip: "Error",
      child: <p>Unknown block type "{block.type}"</p>
    });
  }
}

function convertAtUriToPostUri(uri: string) {
  const atUri = new AtUri(uri);
  return `https://bsky.app/profile/${atUri.host}/post/${atUri.rkey}`;
}

function linkToPopEvent(event: Event) {
  const href = (event.target as HTMLElement).getAttribute('href') || "#null-and-void";
  const target = document.querySelector(href);
  if (target) {
    event.preventDefault();
    const currentPosition = window.scrollY;
    const offset = -64;
    window.location.hash = href;
    window.scrollTo({ top: currentPosition });
    const targetPosition = target.getBoundingClientRect().top + window.scrollY + offset;
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }
}
function linkToStashEvent(event: Event) {
  const href = (event.target as HTMLElement).getAttribute('href') || "#null-and-void";
  const target = document.querySelector(href);
  if (target) {
    event.preventDefault();
    const currentPosition = window.scrollY;
    const offset = 12;
    window.location.hash = href;
    window.scrollTo({ top: currentPosition });
    const targetPosition = target.getBoundingClientRect().bottom + (window.scrollY - window.innerHeight) + offset;
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }
}
