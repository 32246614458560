import React, { useEffect, useRef, useState } from "react";
import "./object.scss";
import * as Typ from "../typography/typography";
import Skeleton from "react-loading-skeleton";

type BlueskyObjectProps = React.HTMLProps<HTMLDivElement> & {
  loading?: boolean,
  /** The title of the object. */
  title: string,
  /** The type name, shown at the beginning of the hint. */
  typeName: string,
  /** The image URL used for its icon. A default may be provided. */
  imageUrl?: string,
  /** The description or body text of the object. */
  description?: string,
  authorDid: string,
  authorHandle?: string,
  primary?: boolean,
}
export function BlueskyObject({ title, imageUrl, description, typeName, authorDid, authorHandle = "handle.invalid", primary = false, loading = false, ...props }: BlueskyObjectProps) {
  const byline = authorDid && <>{typeName} by <a href={`https://bsky.app/profile/${authorDid}`} target={"_blank"}>@{authorHandle}</a></>;
  return <div className={`sfu-bluesky-object sfu-bluesky-object_${typeName}${primary && " sfu-bluesky-object--primary" || ""}`} {...props}>
    <div className="sfu-bluesky-object--header">
      {typeof imageUrl === "string" 
        ? <img className="sfu-bluesky-object--header--avatar" src={imageUrl} aria-hidden />
        : loading 
          ? <Skeleton className="sfu-bluesky-object--header--avatar" />
          : <div className="sfu-bluesky-object--header--avatar sfu-bluesky-object--header--avatar_fallback"></div>
      }
      <div className="sfu-bluesky-object--header--text">
        {primary 
          ? <>
            <Typ.Display as="h2" addClass="sfu-bluesky-object--header--title">{loading ? <Skeleton inline /> : title}</Typ.Display>
            <Typ.Hint addClass="sfu-bluesky-object--header--byline">{loading ? <Skeleton inline /> : byline}</Typ.Hint>
          </>
          : <>
            <Typ.ObjectTitle as="h3" addClass="sfu-bluesky-object--header--title">{loading ? <Skeleton inline width="12ch" /> : title}</Typ.ObjectTitle>
            <Typ.Hint addClass="sfu-bluesky-object--header--byline">{loading ? <Skeleton inline width="23ch" /> : byline}</Typ.Hint>
          </>
        }
      </div>
    </div>
    <div className="sfu-bluesky-object--description">
      <Typ.Body as="p">{loading ? <Skeleton inline count={2.33} /> : description}</Typ.Body>
    </div>
  </div>;
}

