import React from "react";
import "./textField.scss";

export type TextFieldProps = Omit<React.HTMLProps<HTMLInputElement>,"prefix"|"suffix"> & {
  innerClassName?: string,
  prefix?: React.ReactNode,
  suffix?: React.ReactNode,
  containerProps?: Omit<React.HTMLProps<HTMLDivElement>,"className">
};
export function TextField({className, innerClassName, prefix, suffix, containerProps, ...props}: TextFieldProps) {
  return <div 
    className={`sfu-input-text--wrapper${className ? " "+className : ""}`}
    {...containerProps}
  >
    {prefix}
    <input className={`sfu-input-text--inner`} {...props} />
    {suffix}
  </div>
}
