import { WrappedRecordWithAuthorInfo } from "../../types/Record"
import SkyfeedRecordValue from "../../types/SkyfeedBuilder"
import { BlueskyObject } from "./object"
import * as Typ from "../typography/typography";
import * as ATProto from "@atproto/api";
import { DidResolver, getHandle } from "@atproto/identity/dist/did";
import { useEffect, useRef, useState } from "react";
import { AppBskyActorProfile, AppBskyLabelerService, ComAtprotoLabelDefs, ComAtprotoLabelNS } from "@atproto/api";
import { LabelMetadata } from "../../types/Label";
import Skeleton from "react-loading-skeleton";
import { syncResolveBlob } from "../../network/resolveBlob";
import { getLabelerServiceRecord } from "../../network/getLabelDef";
import { getProfile } from "../../network/getProfile";

export type BlueskyLabelTileProps = Omit<React.HTMLProps<HTMLDivElement>,"label"> & {
  label: string | LabelMetadata,
  loading?: boolean,
  shouldLink?: boolean
}
export function BlueskyLabelTile({ label, loading = false, shouldLink, ...props }: BlueskyLabelTileProps) {
  if (loading || !label) return <LabelTile
    //loading={true}
    {...props}
  />
  else if (typeof label == "object") return <KnownLabelTile label={label} shouldLink={shouldLink} {...props} />
  else if (typeof label == "string") return <UnknownLabelTile label={label} shouldLink={shouldLink} {...props} />
  else return <div className='sfu-error'>Unknown label</div>
}

type LabelTileProps = Omit<React.HTMLProps<HTMLDivElement>,"label"|"title"> & {
  title?: string,
  description?: string,
  authorDid?: string,
  authorHandle?: string,
  authorName?: string,
  imageUrl?: string,
  shouldLink?: boolean,
}
function LabelTile({ imageUrl, title, description, authorDid, authorHandle, authorName, shouldLink, ...props }: LabelTileProps) {
  const object = <div {...props} className="sfu-bluesky-label-tile tooltip-below" data-tooltip={description}>
    {typeof imageUrl === "string" 
      ? <img className="sfu-bluesky-label-tile--avatar" src={imageUrl} aria-hidden />
      : authorDid 
        ? <Skeleton className="sfu-bluesky-label-tile--avatar" />
        : <div className="sfu-bluesky-label-tile--avatar sfu-bluesky-label-tile--avatar_fallback"></div>
    }
    <Typ.Body addClass="the-label">{authorName || <>@{authorHandle}</>} / {title}</Typ.Body>
  </div>;
  if (shouldLink) {
    return <a className="sfu-link-tile" target="_blank" href={`https://bsky.app/profile/${authorDid}`}>{object}</a>;
  } else return object;
}

type KnownLabelTileProps = Omit<React.HTMLProps<HTMLDivElement>,"label"> & {
  label: LabelMetadata,
  shouldLink?: boolean,
}
function KnownLabelTile({label, shouldLink, ...props}: KnownLabelTileProps) {
  const handle = getHandle(label.issuer);
  const strings = label.definition.locales.find((v,_,__) => v.lang == "en") || label.definition.locales[0];
  return <LabelTile
    title={strings.name}
    description={strings.description}
    imageUrl={label.issuerProfile?.avatar ? syncResolveBlob(label.issuerProfile!.avatar!, label.issuer) : undefined}
    authorName={label.issuerProfile?.displayName}
    authorDid={label.issuer.id}
    authorHandle={handle}
    shouldLink={shouldLink}
    {...props}
  />;
}

type UnknownLabelTileProps = Omit<React.HTMLProps<HTMLDivElement>,"label"> & {
  label: string,
  shouldLink?: boolean,
}
function UnknownLabelTile({label, shouldLink, ...props}: UnknownLabelTileProps) {
  const [labelerDid, labelValue] = label.split("/", 2);
  const idResolverRef = useRef<DidResolver|undefined>(undefined);
  if (idResolverRef.current === undefined) {
    idResolverRef.current = new DidResolver({});
  }
  const [isLoading, setLoading] = useState(false);
  const [serviceRecordResponse, setServiceRecordResponse] = useState<WrappedRecordWithAuthorInfo<AppBskyLabelerService.Record> | undefined>(undefined);
  const [profileRecordResponse, setProfileRecordResponse] = useState<WrappedRecordWithAuthorInfo<AppBskyActorProfile.Record> | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  useEffect(() => {
    if (serviceRecordResponse) return;
    let discard = false;
    setLoading(true);
    getProfile(labelerDid, {
      setLoading,
      setError,
      idResolver: idResolverRef.current,
      isDiscarded: () => discard
    }).then((value) => {
      if (!discard) getLabelerServiceRecord(labelerDid, {
        setLoading,
        setError,
        didDocument: value?.authorDid,
        idResolver: idResolverRef.current,
        isDiscarded: () => discard
      }).then((value) => setServiceRecordResponse(value));
      return setProfileRecordResponse(value);
    });
    return () => {
      discard = true;
    };
  }, [label]);

  if (error) return <div className='sfu-error'>{error}</div>;
  if (!profileRecordResponse?.value) return <div className='sfu-error'>Could not fetch profile</div>;

  const definition = serviceRecordResponse?.value.policies.labelValueDefinitions?.find((v,_,__) => v.identifier == labelValue) || {
    identifier: labelValue,
    locales: [{
      lang: "en",
      name: labelValue,
      description: ""
    }],
    defaultSetting: "ignore",
    blurs: "none",
    severity: "none",
    adultOnly: false,
  };

  return <KnownLabelTile label={{
    issuerProfile: profileRecordResponse?.value,
    issuer: profileRecordResponse?.authorDid ?? {
      id: labelerDid
    },
    definition: definition,
  }} shouldLink={shouldLink} />;
}
